import { Application } from "@hotwired/stimulus"
import HoneyBadger from '@honeybadger-io/js'

// TODO improve Honeybadger reporting to make it more readable
const honeybadger = HoneyBadger.configure({
  apiKey: window._honeybadger_api_key,
  environment: process.env.NODE_ENV,
  revision: 'git SHA/project version',
})

// Function to generate a SHA-256 hash in pure JavaScript
async function generateFingerprint(stackFrame) {
  const { file, line, method } = stackFrame;

  try {
    // Extract the file path without the domain
    const url = new URL(file);
    const filePath = url.pathname; // Removes protocol and domain

    // Concatenate the relevant data
    const fingerprintData = `${filePath}:${line}:${method}`;

    // Convert to a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(fingerprintData);

    // Compute SHA-256 hash
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // Convert to hex string
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  } catch (error) {
    console.error("Error generating fingerprint:", error);
    return null; // Fallback if hashing fails
  }
}

// Modify Honeybadger before reporting
honeybadger.beforeNotify(async (notice) => {
  if (notice.backtrace && notice.backtrace.length > 0) {
    const firstFrame = notice.backtrace[0];
    const fingerprint = await generateFingerprint(firstFrame);

    if (fingerprint) {
      notice.fingerprint = fingerprint;
    }
  }
});

const application = Application.start()

application.handleError = (error, message, detail) => {
  if (process.env.NODE_ENV != 'production' && typeof console !== 'undefined' && console.warn) {
    console.warn(message, detail);
  }
  HoneyBadger.notify(error)
}

// Configure Stimulus development experience
application.debug    = false
window.Stimulus      = application

// if(process.env.NODE_ENV == 'production'){
//   var console = {};
//   console.log = function(){};
//   console.warn = function(){};
//   console.error = function(){};
//   console.table = function(){};
//   console.assert = function(){};
//   console.count = function(){};
//   console.debug = function(){};
//   console.dir = function(){};
//   console.dirxml = function(){};
//   console.exception = function(){};
//   console.group = function(){};
//   console.groupCollapsed = function(){};
//   console.info = function(){};
//   console.profile = function(){};
//   console.table = function(){};
//   console.timeLog = function(){};
//   console.timeStamp = function(){};
//   console.trace = function(){};
//   window.console = console;
// }

export { application }
