const useSwiperConfig = controller => {
  Object.assign(controller, {
    get config_widgets() {
      const slideCount = controller.slideCount;
      return {
        loop: true,
        enabled: slideCount > 1,
        slidesPerView: Math.min(1, slideCount), //* BS XS
        breakpoints: {
          576: { //* BS SM
            enabled: slideCount > 2,
            slidesPerView: Math.min(3, slideCount),
          },
          768: { //* BS MD
            enabled: slideCount > 3,
            slidesPerView: Math.min(3, slideCount),
          },
          // 992: { //* BS LG
          //   enabled: slideCount > 3,
          //   slidesPerView: Math.min(3.5, slideCount),
          // },
          // 1200: { //* BS XL
          //   enabled: slideCount > 4,
          //   slidesPerView: Math.min(4.5, slideCount),
          // },
          // 1400: { //* BS XXL
          //   enabled: slideCount > 5,
          //   slidesPerView: Math.min(5.5, slideCount),
          // },
        },
      };
    },
    get config_website_features() {
      const slideCount = controller.slideCount;
      return {
        loop: slideCount > 1,
        enabled: slideCount > 1,
        slidesPerView: Math.min(1.2, slideCount), //* BS XS
        spaceBetween: 20,
        lazy: {
          loadPrevNext: false,
          enabled: true,
          checkInView: true,
        },
        breakpoints: {
          576: { //* BS SM
            // loop: slideCount > 2,
            enabled: slideCount > 2,
            slidesPerView: Math.min(1.5, slideCount),
          },
          768: { //* BS MD
            loop: slideCount > 2,
            enabled: slideCount > 2,
            slidesPerView: Math.min(2.5, slideCount),
          },
          992: { //* BS LG
            // loop: slideCount > 3,
            enabled: slideCount > 2,
            spaceBetween: 40,
            slidesPerView: Math.min(2.5, slideCount),
          },
          1200: { //* BS XL
            // loop: slideCount > 4,
            enabled: slideCount > 3,
            slidesPerView: Math.min(3.5, slideCount),
          },
          // 1400: { //* BS XXL
          //   loop: slideCount > 5,
          //   enabled: slideCount > 5,
          //   slidesPerView: Math.min(5.5, slideCount),
          // },
        },
      };
    },
    get config_room_preview() {
      const slideCount = controller.slideCount;
      return {
        loop: true,
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: {
          loadPrevNext: false,
          enabled: true,
          checkInView: true,
        },
        enabled: slideCount > 1,
        slidesPerView: 1,
        on: {
          realIndexChange: (swiper) => {
            if (!swiper.params.lazy.loadPrevNext) {
              swiper.params.lazy.loadPrevNext = true;
            }
          },
        },
      };
    },
    get config_associated_hotels() {
      const slideCount = controller.slideCount;
      return {
        loop: slideCount > 1,
        enabled: slideCount > 1,
        slidesPerView: 1.1,
        spaceBetween: 5,
        breakpoints: {
          576: { //* BS SM
            slidesPerView: 1.25,
          },
          768: { //* BS MD
            slidesPerView: 1.1,
          },
          992: { //* BS LG
            slidesPerView: 1,
          },
        },
      };
    },
    get config_gt_places_nearby_accommodations() {
      const slideCount = controller.slideCount;
      return {
        loop: slideCount > 1,
        enabled: slideCount > 1,
        slidesPerView: 1.1,
        spaceBetween: 10,
        breakpoints: {
          640: { //* TW SM
            loop: slideCount > 1,
            enabled: slideCount > 1,
            slidesPerView: Math.min(1, slideCount),
            spaceBetween: 20,
          },
          768: { //* TW MD
            loop: slideCount > 1,
            enabled: slideCount > 1,
            slidesPerView: Math.min(1, slideCount),
            spaceBetween: 15,
          },
          1024: { //* TW LG
            loop: slideCount > 2,
            enabled: slideCount > 2,
            slidesPerView: Math.min(2, slideCount),
            spaceBetween: 15,
          },
          1280: { //* TW XL
            loop: slideCount > 3,
            enabled: slideCount > 3,
            slidesPerView: Math.min(3, slideCount),
            spaceBetween: 15,
          },
          1536: { //* TW 2XL
            loop: slideCount > 4,
            enabled: slideCount > 4,
            slidesPerView: Math.min(4, slideCount),
            spaceBetween: 15,
          },
        },
      };
    },
  });
};

export { useSwiperConfig };
export default useSwiperConfig;
